import { template as template_825500f476cd4c748e3197dbf7ebe65a } from "@ember/template-compiler";
const FKLabel = template_825500f476cd4c748e3197dbf7ebe65a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
