import { template as template_61043150bbac4f59a76b4cf23efb5468 } from "@ember/template-compiler";
const FKControlMenuContainer = template_61043150bbac4f59a76b4cf23efb5468(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
