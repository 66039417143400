import { template as template_0616bcb3a7c8432d86a55cb6a6f14ad4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0616bcb3a7c8432d86a55cb6a6f14ad4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
