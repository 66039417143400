import { template as template_f2b91e94bffe4f58b917ad867308556c } from "@ember/template-compiler";
const FKText = template_f2b91e94bffe4f58b917ad867308556c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
